'use client';

import React, { useState, useEffect, useRef, Fragment } from 'react';

import { stripHtml } from '@/helpers/text/textFormatting';

import Typography from '@/components/_ui/_blocks/Typography/Typography';
import Icon from '../_blocks/Icon/Icon';

import styles from './Accordion.module.css';

export interface AccordionItemProps {
    /** Injected by Accordion - this is the index of the accordion item in the array. */
    index?: number | undefined;
    /**  Injected by Accordion - this determines whether the accordion item needs to be open or not. */
    open?: boolean | undefined;
    /**  Injected by Accordion - this determines whether the accordion is enabled. */
    enabled?: boolean | undefined;
    /**  Injected by Accordion - this determines whether to hide headings when accordion is disabled. */
    hideHeadings?: boolean | undefined;
    /**  Injected by Accordion - this handles the open callback with the Accordion component, passing through the index. */
    handleOpen?: ((index: number | null, ref?: any) => void) | undefined;
    /** This is the heading of the accordion item. */
    heading: string;
    /** This is the class name for heading of the accordion item heading. */
    headingClassName?: string;
    /** Class name for the accordion item */
    itemClassName?: string;
    /** Id for the accordion item */
    itemId: string | undefined;
    /** This is the content of the accordion item. */
    children: React.ReactNode;
}

/**
 * Accordion Item for displaying the accordion header and content.
 * The index, open and handle open gets passed in to this component via the Accordion component.
 * @param {*} props
 */
const AccordionItem = ({
    children,
    heading,
    index = undefined,
    open = undefined,
    enabled = undefined,
    hideHeadings = undefined,
    handleOpen = undefined,
    headingClassName = '',
    itemClassName = '',
    itemId = undefined,
}: AccordionItemProps) => {
    const accordionItemRef = useRef<HTMLLIElement | null>(null);
    const [maxHeight, setMaxHeight] = useState('0px');

    const itemRef = React.createRef<HTMLDivElement>();

    useEffect(() => {
        if (typeof window !== 'undefined' && window.location.hash === `#${itemId}`) handleOpen!(index!, accordionItemRef);
    }, []);

    useEffect(() => {
        if (!itemRef.current) return;
        const height = itemRef.current.clientHeight;
        setMaxHeight(open ? `${height + height / 2}px` : '0px');
    }, [itemRef, open]);

    const strippedHeading = stripHtml(heading);

    return (
        <li
            ref={accordionItemRef}
            className={`${styles.accordion__item} ${itemClassName} ${open ? styles.accordion__item_is_active : ''}`}
            id={itemId}
            data-testid={itemId}
        >
            <div className={styles.accordionItemTitle}>
                {!hideHeadings ? (
                    <Fragment>
                        <Typography
                            as="h3"
                            typeset="heading"
                            size="100"
                            lineHeight="200"
                            className={headingClassName}
                            dangerouslySetInnerHTML={{ __html: heading }}
                        />
                        <button
                            title={strippedHeading}
                            aria-label={strippedHeading}
                            className="flex justify-end"
                            type="button"
                            onClick={() => {
                                handleOpen!(open ? null : index!);
                            }}
                        >
                            {enabled ? (
                                <Icon
                                    icon="chevron-right-light"
                                    alt=""
                                    aria-hidden="true"
                                    className={styles.accordion__icon}
                                    data-testid="enabled-button"
                                    size="medium"
                                />
                            ) : null}
                        </button>
                    </Fragment>
                ) : null}
            </div>
            <div className={`${styles.accordion__content} ${!open ? `${styles.is_collapsed}` : ''}`} style={enabled ? { maxHeight } : {}}>
                <div ref={itemRef}>{children}</div>
            </div>
        </li>
    );
};

export default AccordionItem;
